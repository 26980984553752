/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBanners = /* GraphQL */ `
  query GetBanners($yearQuarter_startDate_endDate: String!) {
    getBanners(yearQuarter_startDate_endDate: $yearQuarter_startDate_endDate) {
      desktopImage
      desktopImageAlt
      desktopLink
      id
      mobileImage
      mobileImageAlt
      mobileLink
      slot
      storeIds
      storeRegions
      yearQuarter_startDate_endDate
      version
    }
  }
`;
export const getMetadata = /* GraphQL */ `
  query GetMetadata($business: String!) {
    getMetadata(business: $business) {
      business
      metadata
      version
    }
  }
`;
export const getPromotion = /* GraphQL */ `
  query GetPromotion($yearQuarter_startDate_endDate: String!, $id: String!) {
    getPromotion(
      yearQuarter_startDate_endDate: $yearQuarter_startDate_endDate
      id: $id
    ) {
      asins
      asinImage
      auditedStoreRegions
      circularPrice
      circularPriceDisplay
      description
      discountTag
      eachPriceForF3RS
      id
      imageStatus
      isAplenty
      isFreshBrand
      isCategoryHero
      isCoverPageHero
      isLocal
      isPrimeBenefit
      isPrivateBrand
      isUSDAChoice
      isUSDAOrganic
      isSoftDeleted
      isLocked
      isVendorFunded
      notes
      page
      pawsId
      percentFunded
      picturedAsins
      priceConditions
      primeMemberLabel
      primeMemberLabelDisplay
      primeDiscount
      productCategory
      promotionEndDate
      promotionType
      status
      storeIds
      storeRegions
      title
      unitOfMeasure
      version
      vertical
      vendorManager
      yearQuarter_startDate_endDate
      zoneNumber
    }
  }
`;
export const getPromotionsForWeek = /* GraphQL */ `
  query GetPromotionsForWeek($yearQuarter_startDate_endDate: String!) {
    getPromotionsForWeek(
      yearQuarter_startDate_endDate: $yearQuarter_startDate_endDate
    ) {
      asins
      asinImage
      auditedStoreRegions
      circularPrice
      circularPriceDisplay
      description
      discountTag
      eachPriceForF3RS
      id
      imageStatus
      isAplenty
      isFreshBrand
      isCategoryHero
      isCoverPageHero
      isLocal
      isPrimeBenefit
      isPrivateBrand
      isUSDAChoice
      isUSDAOrganic
      isSoftDeleted
      isLocked
      isVendorFunded
      notes
      page
      pawsId
      percentFunded
      picturedAsins
      priceConditions
      primeMemberLabel
      primeMemberLabelDisplay
      primeDiscount
      productCategory
      promotionEndDate
      promotionType
      status
      storeIds
      storeRegions
      title
      unitOfMeasure
      version
      vertical
      vendorManager
      yearQuarter_startDate_endDate
      zoneNumber
    }
  }
`;
export const getPromotionWeek = /* GraphQL */ `
  query GetPromotionWeek($yearQuarter: String!, $startDate_endDate: String!) {
    getPromotionWeek(
      yearQuarter: $yearQuarter
      startDate_endDate: $startDate_endDate
    ) {
      name
      startDate_endDate
      editDeadline
      printDeadline
      reviewDeadline
      templateId
      templateName
      template
      version
      yearQuarter
    }
  }
`;
export const getPromotionWeeksForYearQuarter = /* GraphQL */ `
  query GetPromotionWeeksForYearQuarter($yearQuarter: String!) {
    getPromotionWeeksForYearQuarter(yearQuarter: $yearQuarter) {
      name
      startDate_endDate
      editDeadline
      printDeadline
      reviewDeadline
      templateId
      templateName
      template
      version
      yearQuarter
    }
  }
`;
export const getPublishedPromotion = /* GraphQL */ `
  query GetPublishedPromotion(
    $yearQuarter: String!
    $startDate_endDate: String!
  ) {
    getPublishedPromotion(
      yearQuarter: $yearQuarter
      startDate_endDate: $startDate_endDate
    ) {
      promotions
      startDate_endDate
      version
      yearQuarter
    }
  }
`;
export const getPublishedPromotionsForYearQuarter = /* GraphQL */ `
  query GetPublishedPromotionsForYearQuarter($yearQuarter: String!) {
    getPublishedPromotionsForYearQuarter(yearQuarter: $yearQuarter) {
      promotions
      startDate_endDate
      version
      yearQuarter
    }
  }
`;
export const getTemplate = /* GraphQL */ `
  query GetTemplate($id: String!) {
    getTemplate(id: $id) {
      id
      name
      promoConfiguration
      version
    }
  }
`;
export const getAllTemplates = /* GraphQL */ `
  query GetAllTemplates {
    getAllTemplates {
      id
      name
      promoConfiguration
      version
    }
  }
`;
export const getMetadataAuditLog = /* GraphQL */ `
  query GetMetadataAuditLog($business: String!) {
    getMetadataAuditLog(business: $business) {
      business
      version
      metadata {
        business
        metadata
        version
      }
      auditLogMetadata {
        modificationType
        modificationTimestamp
        username
      }
    }
  }
`;
export const getPromotionAuditLog = /* GraphQL */ `
  query GetPromotionAuditLog($id: String!) {
    getPromotionAuditLog(id: $id) {
      id
      version
      promotion {
        asins
        asinImage
        auditedStoreRegions
        circularPrice
        circularPriceDisplay
        description
        discountTag
        eachPriceForF3RS
        id
        imageStatus
        isAplenty
        isFreshBrand
        isCategoryHero
        isCoverPageHero
        isLocal
        isPrimeBenefit
        isPrivateBrand
        isUSDAChoice
        isUSDAOrganic
        isSoftDeleted
        isLocked
        isVendorFunded
        notes
        page
        pawsId
        percentFunded
        picturedAsins
        priceConditions
        primeMemberLabel
        primeMemberLabelDisplay
        primeDiscount
        productCategory
        promotionEndDate
        promotionType
        status
        storeIds
        storeRegions
        title
        unitOfMeasure
        version
        vertical
        vendorManager
        yearQuarter_startDate_endDate
        zoneNumber
      }
      auditLogMetadata {
        modificationType
        modificationTimestamp
        username
      }
    }
  }
`;
export const getPromotionWeekAuditLog = /* GraphQL */ `
  query GetPromotionWeekAuditLog($yearQuarter_startDate_endDate: String!) {
    getPromotionWeekAuditLog(
      yearQuarter_startDate_endDate: $yearQuarter_startDate_endDate
    ) {
      yearQuarter_startDate_endDate
      version
      promotionWeek {
        name
        startDate_endDate
        editDeadline
        printDeadline
        reviewDeadline
        templateId
        templateName
        template
        version
        yearQuarter
      }
      auditLogMetadata {
        modificationType
        modificationTimestamp
        username
      }
    }
  }
`;
export const getPublishedPromotionAuditLog = /* GraphQL */ `
  query GetPublishedPromotionAuditLog($yearQuarter_startDate_endDate: String!) {
    getPublishedPromotionAuditLog(
      yearQuarter_startDate_endDate: $yearQuarter_startDate_endDate
    ) {
      yearQuarter_startDate_endDate
      version
      publishedPromotion {
        promotions
        startDate_endDate
        version
        yearQuarter
      }
      auditLogMetadata {
        modificationType
        modificationTimestamp
        username
      }
    }
  }
`;
export const getTemplateAuditLog = /* GraphQL */ `
  query GetTemplateAuditLog($id: String!) {
    getTemplateAuditLog(id: $id) {
      id
      version
      template {
        id
        name
        promoConfiguration
        version
      }
      auditLogMetadata {
        modificationType
        modificationTimestamp
        username
      }
    }
  }
`;
export const getAsinViability = /* GraphQL */ `
  query GetAsinViability($input: GetAsinViabilityInput!) {
    getAsinViability(input: $input) {
      asin
      viability {
        regionName
        acceptableAvailability
        availableStores
        unavailableStores
        availability
        isAvailable
        storeRegions {
          storeRegionName
          availableAsinViabilities {
            asin
            state
            store_id
            site_id
            StandardizedGEO
            procurable_status
            Planogram_Status
            need_by_start_date
            need_by_end_date
            instore_buyability_status
            isAvailable
            is_jwo_enabled
            Shelf_UWI
            UWICheck
            Vendor_Fill_Rate
            VFRCheck
          }
          unavailableAsinViabilities {
            asin
            state
            store_id
            site_id
            StandardizedGEO
            procurable_status
            Planogram_Status
            need_by_start_date
            need_by_end_date
            instore_buyability_status
            isAvailable
            is_jwo_enabled
            Shelf_UWI
            UWICheck
            Vendor_Fill_Rate
            VFRCheck
          }
        }
      }
    }
  }
`;
export const getProductImages = /* GraphQL */ `
  query GetProductImages($asin: String!) {
    getProductImages(asin: $asin)
  }
`;
export const generateDigitalCircularParams = /* GraphQL */ `
  query GenerateDigitalCircularParams($input: DigitalCircularParamsInput!) {
    generateDigitalCircularParams(input: $input)
  }
`;
