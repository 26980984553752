import { Columns, PromotionStatuses } from './index';

export const Language = Object.freeze({
  ROWS: 'Rows',
  RELATED_ROWS: 'Related Rows',
  RELATED_ROWS_WITH_EDLP: 'Related Rows with EDLP',
  RELATED_ROWS_WITH_PRIME_BENEFIT: 'Related Rows with Prime Benefit',
  INACTIVE_ROW: 'Inactive Row',
  ACTIVE_ROW: 'Active Row',
  PRINT_LOCKED_ROW: 'Print locked row',
  SOFT_DELETED_ROW: 'Soft deleted row',
  EDPL_ROW: 'EDLP row',
  PRIME_BENEFIT_ROW: 'Prime Benefit row',
  SELECTED_ROW: 'Selected row',
  ROW_WITH_AN_ERROR: `Row with an error (red triangle indicate something is wrong). 
  To learn what's wrong, hover your mouse over it.`,
  NORMAL_CELLS_DESCRIPTION: `This section displays a collection of generic cells used by 
  the application. Cells in this section are normally visible to all application customers 
  regardless of their role.`,
  AUDIT_CELLS_DESCRIPTION: `The cells in this section are used for auditing purposes. 
  Typically, they become available after a promotion has been marked as edit locked.`,
  CELLS: 'Cells',
  CELLS_AUDIT_MODE: 'Cells (Audit mode)',
  INACTIVE_CELL: 'Inactive cell',
  ACTIVE_CELL: 'Active cell',
  NEW_CONTENT_ADDED_CELL: 'New content added',
  CONTENT_UPDATED_CELL: 'Content updated',
  CONTENT_REMOVED_CELL: 'Content removed',
  CELL_WITH_AN_ERROR: `Cell with an error (red triangle indicates something is wrong). 
  To learn what's wrong, hover your mouse over it.`,
  MODAL_WINDOW_TITLE: 'Color Legend',
  ROWS_DESCRIPTION: `The same principle applies to rows as well. 
  Each row may have a different state depending on what it represents.`,
  RELATED_ROWS_DESCRIPTION: `Rows within a group that are related are highlighted with the same color.
  A row is considered related when it has the same ${Columns.PAGE.display},
  ${Columns.ZONE_NUMBER.display}, and ${Columns.TITLE.display}.`,
  RELATED_EDLP_ROWS_DESCRIPTION: `EDLP rows related to one another are highlighted in a lighter 
  color than their original highlighting color.`,
  RELATED_PRIME_BENEFITS_ROWS_DESCRIPTION: `The same applies to the Prime Benefits rows. 
  The lighter color is also used to highlight this group of related rows.`,
});

export const ColumnOrder = [
  Columns.PAGE,
  Columns.ZONE_NUMBER,
  Columns.STORE_REGIONS,
  Columns.TITLE,
  Columns.ASINS,
];

export const SingleCellColumnOrder = [
  Columns.VERTICAL,
];

export const DemoPromotion = {
  [Columns.PAGE.name]: 1,
  [Columns.ZONE_NUMBER.name]: 1,
  [Columns.STORE_REGIONS.name]: ['LA1', 'CA2', 'WA1', 'WA2'],
  [Columns.AUDITED_STORE_REGIONS.name]: ['LA1', 'CA2', 'WA1', 'WA2'],
  [Columns.TITLE.name]: 'Organic Oranges',
  [Columns.ASINS.name]: 'B003O3ACJ4, B000R9EEH4, B000P6J0SM',
};