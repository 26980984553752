/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBanner = /* GraphQL */ `
  mutation CreateBanner($input: CreateBannerInput!) {
    createBanner(input: $input) {
      desktopImage
      desktopImageAlt
      desktopLink
      id
      mobileImage
      mobileImageAlt
      mobileLink
      slot
      storeIds
      storeRegions
      yearQuarter_startDate_endDate
      version
    }
  }
`;
export const updateBanner = /* GraphQL */ `
  mutation UpdateBanner($input: UpdateBannerInput!) {
    updateBanner(input: $input) {
      desktopImage
      desktopImageAlt
      desktopLink
      id
      mobileImage
      mobileImageAlt
      mobileLink
      slot
      storeIds
      storeRegions
      yearQuarter_startDate_endDate
      version
    }
  }
`;
export const deleteBanner = /* GraphQL */ `
  mutation DeleteBanner($input: DeleteBannerInput!) {
    deleteBanner(input: $input) {
      desktopImage
      desktopImageAlt
      desktopLink
      id
      mobileImage
      mobileImageAlt
      mobileLink
      slot
      storeIds
      storeRegions
      yearQuarter_startDate_endDate
      version
    }
  }
`;
export const updateMetadata = /* GraphQL */ `
  mutation UpdateMetadata($input: UpdateMetadataInput!) {
    updateMetadata(input: $input) {
      business
      metadata
      version
    }
  }
`;
export const createPromotion = /* GraphQL */ `
  mutation CreatePromotion($input: CreatePromotionInput!) {
    createPromotion(input: $input) {
      asins
      asinImage
      auditedStoreRegions
      circularPrice
      circularPriceDisplay
      description
      discountTag
      eachPriceForF3RS
      id
      imageStatus
      isAplenty
      isFreshBrand
      isCategoryHero
      isCoverPageHero
      isLocal
      isPrimeBenefit
      isPrivateBrand
      isUSDAChoice
      isUSDAOrganic
      isSoftDeleted
      isLocked
      isVendorFunded
      notes
      page
      pawsId
      percentFunded
      picturedAsins
      priceConditions
      primeMemberLabel
      primeMemberLabelDisplay
      primeDiscount
      productCategory
      promotionEndDate
      promotionType
      status
      storeIds
      storeRegions
      title
      unitOfMeasure
      version
      vertical
      vendorManager
      yearQuarter_startDate_endDate
      zoneNumber
    }
  }
`;
export const updatePromotion = /* GraphQL */ `
  mutation UpdatePromotion($input: UpdatePromotionInput!) {
    updatePromotion(input: $input) {
      asins
      asinImage
      auditedStoreRegions
      circularPrice
      circularPriceDisplay
      description
      discountTag
      eachPriceForF3RS
      id
      imageStatus
      isAplenty
      isFreshBrand
      isCategoryHero
      isCoverPageHero
      isLocal
      isPrimeBenefit
      isPrivateBrand
      isUSDAChoice
      isUSDAOrganic
      isSoftDeleted
      isLocked
      isVendorFunded
      notes
      page
      pawsId
      percentFunded
      picturedAsins
      priceConditions
      primeMemberLabel
      primeMemberLabelDisplay
      primeDiscount
      productCategory
      promotionEndDate
      promotionType
      status
      storeIds
      storeRegions
      title
      unitOfMeasure
      version
      vertical
      vendorManager
      yearQuarter_startDate_endDate
      zoneNumber
    }
  }
`;
export const deletePromotion = /* GraphQL */ `
  mutation DeletePromotion($input: DeletePromotionInput!) {
    deletePromotion(input: $input) {
      asins
      asinImage
      auditedStoreRegions
      circularPrice
      circularPriceDisplay
      description
      discountTag
      eachPriceForF3RS
      id
      imageStatus
      isAplenty
      isFreshBrand
      isCategoryHero
      isCoverPageHero
      isLocal
      isPrimeBenefit
      isPrivateBrand
      isUSDAChoice
      isUSDAOrganic
      isSoftDeleted
      isLocked
      isVendorFunded
      notes
      page
      pawsId
      percentFunded
      picturedAsins
      priceConditions
      primeMemberLabel
      primeMemberLabelDisplay
      primeDiscount
      productCategory
      promotionEndDate
      promotionType
      status
      storeIds
      storeRegions
      title
      unitOfMeasure
      version
      vertical
      vendorManager
      yearQuarter_startDate_endDate
      zoneNumber
    }
  }
`;
export const approvePromotionChange = /* GraphQL */ `
  mutation ApprovePromotionChange($input: LockedPromotionUpdateInput!) {
    approvePromotionChange(input: $input) {
      asins
      asinImage
      auditedStoreRegions
      circularPrice
      circularPriceDisplay
      description
      discountTag
      eachPriceForF3RS
      id
      imageStatus
      isAplenty
      isFreshBrand
      isCategoryHero
      isCoverPageHero
      isLocal
      isPrimeBenefit
      isPrivateBrand
      isUSDAChoice
      isUSDAOrganic
      isSoftDeleted
      isLocked
      isVendorFunded
      notes
      page
      pawsId
      percentFunded
      picturedAsins
      priceConditions
      primeMemberLabel
      primeMemberLabelDisplay
      primeDiscount
      productCategory
      promotionEndDate
      promotionType
      status
      storeIds
      storeRegions
      title
      unitOfMeasure
      version
      vertical
      vendorManager
      yearQuarter_startDate_endDate
      zoneNumber
    }
  }
`;
export const rejectPromotionChange = /* GraphQL */ `
  mutation RejectPromotionChange($input: LockedPromotionUpdateInput!) {
    rejectPromotionChange(input: $input) {
      asins
      asinImage
      auditedStoreRegions
      circularPrice
      circularPriceDisplay
      description
      discountTag
      eachPriceForF3RS
      id
      imageStatus
      isAplenty
      isFreshBrand
      isCategoryHero
      isCoverPageHero
      isLocal
      isPrimeBenefit
      isPrivateBrand
      isUSDAChoice
      isUSDAOrganic
      isSoftDeleted
      isLocked
      isVendorFunded
      notes
      page
      pawsId
      percentFunded
      picturedAsins
      priceConditions
      primeMemberLabel
      primeMemberLabelDisplay
      primeDiscount
      productCategory
      promotionEndDate
      promotionType
      status
      storeIds
      storeRegions
      title
      unitOfMeasure
      version
      vertical
      vendorManager
      yearQuarter_startDate_endDate
      zoneNumber
    }
  }
`;
export const createPromotionWeek = /* GraphQL */ `
  mutation CreatePromotionWeek($input: CreatePromotionWeekInput!) {
    createPromotionWeek(input: $input) {
      name
      startDate_endDate
      editDeadline
      printDeadline
      reviewDeadline
      templateId
      templateName
      template
      version
      yearQuarter
    }
  }
`;
export const updatePromotionWeek = /* GraphQL */ `
  mutation UpdatePromotionWeek($input: UpdatePromotionWeekInput!) {
    updatePromotionWeek(input: $input) {
      name
      startDate_endDate
      editDeadline
      printDeadline
      reviewDeadline
      templateId
      templateName
      template
      version
      yearQuarter
    }
  }
`;
export const deletePromotionWeek = /* GraphQL */ `
  mutation DeletePromotionWeek($input: DeletePromotionWeekInput!) {
    deletePromotionWeek(input: $input) {
      name
      startDate_endDate
      editDeadline
      printDeadline
      reviewDeadline
      templateId
      templateName
      template
      version
      yearQuarter
    }
  }
`;
export const createTemplate = /* GraphQL */ `
  mutation CreateTemplate($input: CreateTemplateInput!) {
    createTemplate(input: $input) {
      id
      name
      promoConfiguration
      version
    }
  }
`;
export const updateTemplate = /* GraphQL */ `
  mutation UpdateTemplate($input: UpdateTemplateInput!) {
    updateTemplate(input: $input) {
      id
      name
      promoConfiguration
      version
    }
  }
`;
export const deleteTemplate = /* GraphQL */ `
  mutation DeleteTemplate($input: DeleteTemplateInput!) {
    deleteTemplate(input: $input) {
      id
      name
      promoConfiguration
      version
    }
  }
`;
export const publishPromotions = /* GraphQL */ `
  mutation PublishPromotions($input: PublishPromotionsInput!) {
    publishPromotions(input: $input) {
      promotions
      startDate_endDate
      version
      yearQuarter
    }
  }
`;
