import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Modal from '@amzn/meridian/modal';
import Heading from '@amzn/meridian/heading';
import GridViewHeader from '../GridViewHeader/GridViewHeader';
import GridViewRow from '../GridViewRow/GridViewRow';
import { Columns, PopOverDirection } from '../../constants';
import { Language as ColorLegendLanguage } from '../../constants/colorLegend';
import { promotionColumnProps } from '../../proptypes';
import gridViewStyles from '../GridView/GridView.module.scss';
import styles from './ColorLegendModal.module.scss';
import { buildGridViewCellProperties } from '../GridViewRow';

export const PAGE_ZONE_NUMBER_COMBINATIONS = [
  [1, 1, 'Organic Blueberries'],
  [1, 2],
  [1, 2],
  [1, 2],
  [1, 2, 'Organic Bananas'],
  [1, 4, 'Organic Apples'],
  [1, 5, 'Organic Mango 50% off'],
];

export const getRow = (promotion, columnOrder, rowPropsOverrides = {}, promotionOverrides = {}) => {
  const rowProps = {
    promotion: {
      ...promotion,
      ...promotionOverrides,
    },
    columnOrder,
    popOverDirection: PopOverDirection.UP,
    shouldStylize: true,
    ...rowPropsOverrides,
  }
  rowProps.gridViewCellProperties = buildGridViewCellProperties(rowProps);

  return (
    // this imports GridViewRow component, not the container
    <GridViewRow {...rowProps} />
  );
};

export const getHighlightedRelatedRows = (promotion, columnOrder, highlightedRowsParams = {}, promotionOverrides = {}) => {
  return PAGE_ZONE_NUMBER_COMBINATIONS
    .map(([page, zoneNumber, title], promotionRowIndex) => {
      const rowPropsOverrides = {
        shouldHighlight: promotionRowIndex >= 1 && promotionRowIndex <= 3,
        ...highlightedRowsParams,
      };

      return getRow(promotion, columnOrder, rowPropsOverrides, {
        [Columns.PAGE.name]: page,
        [Columns.ZONE_NUMBER.name]: zoneNumber,
        ...promotionOverrides,
        ...(title ? { [Columns.TITLE.name]: title } : {})
      });
    });
};

const ColorLegendModal = (props) => {
  const {
    demoPromotion,
    columnOrder,
    singleCellColumnOrder,
    isColorLegendModalOpen,
    onClose,
  } = props;

  const getTableHeaderRows = (headerRowPropsOverrides = {}) => {
    const headerRowProps = {
      columns: columnOrder,
      ...headerRowPropsOverrides,
    };

    return (
      <GridViewHeader {...headerRowProps} />
    );
  };

  const getTable = (rows, options = {}) => {
    const {
      customStyles = {},
      headerRowPropsOverrides = {},
      showHeaders = false,
    } = options;

    const tableHeaderRows = showHeaders
      ? getTableHeaderRows(headerRowPropsOverrides)
      : null;

    return (
      <table
        className={classnames({
          [gridViewStyles.gridViewTable]: true,
          ...customStyles,
        })}
      >
        <tbody>
          {tableHeaderRows}
          {rows}
        </tbody>
      </table>
    );
  };

  const getInactiveRow = () => getTable(getRow(demoPromotion, columnOrder));

  const getActiveRow = () => getTable(getRow(demoPromotion, columnOrder, {
    isActive: true,
  }));

  const getEdlpRow = () => getTable(getRow(demoPromotion, columnOrder, {}, {
    isEdlp: true,
  }));

  const getPrimeBenefitRow = () => getTable(getRow(demoPromotion, columnOrder, {}, {
    isPrimeBenefit: true,
  }));

  const getSoftDeletedRow = () => getTable(getRow(demoPromotion, columnOrder, {}, {
    isSoftDeleted: true,
  }));

  const getPrintLockedRow = () => getTable(getRow(demoPromotion, columnOrder, {
    isReadOnly: true,
  }));

  const getSelectedRow = () => getTable(getRow(demoPromotion, columnOrder, {
    isSelected: true,
  }));

  const getRowWithAnError = () => {
    const rowPropsOverrides = {};
    const promotionOverrides = {
      [Columns.STORE_REGIONS.name]: ['LA1', 'CA2', 'WA1', 'WA2'],
      [Columns.AUDITED_STORE_REGIONS.name]: ['LA1', 'CA2'],
    };

    return getTable(getRow(demoPromotion, columnOrder, rowPropsOverrides, promotionOverrides));
  }

  const getCellWithAnError = () => {
    const rowPropsOverrides = {
      columnOrder: [
        Columns.STORE_REGIONS,
      ],
    };
    const promotionOverrides = {
      [Columns.STORE_REGIONS.name]: ['LA1', 'CA2', 'WA1', 'WA2'],
      [Columns.AUDITED_STORE_REGIONS.name]: ['LA1', 'CA2'],
    };

    return getTable(getRow(demoPromotion, columnOrder, rowPropsOverrides, promotionOverrides));
  }

  const getInactiveCell = () => {
    const rowPropsOverrides = {
      columnOrder: singleCellColumnOrder,
    };
    const promotionOverrides = {
      [Columns.VERTICAL.name]: 'some text',
    };

    return getTable(getRow(demoPromotion, columnOrder, rowPropsOverrides, promotionOverrides));
  }

  const getActiveCell = () => {
    const customStyles = {
      [styles.overrideHighlightActiveCell]: true,
    }
    const rowPropsOverrides = {
      columnOrder: singleCellColumnOrder,
      isActive: true,
    };
    const promotionOverrides = {
      [Columns.VERTICAL.name]: 'some text',
    };

    return getTable(
      getRow(demoPromotion, columnOrder, rowPropsOverrides, promotionOverrides),
      { customStyles }
    );
  }

  const getCellWithNewContent = () => {
    const rowPropsOverrides = {
      columnOrder: singleCellColumnOrder,
      showComparison: true,
      columnsToCompare: [Columns.VERTICAL.name],
    };
    const promotionOverrides = {
      [Columns.VERTICAL.name]: 'some text',
    };

    return getTable(getRow(demoPromotion, columnOrder, rowPropsOverrides, promotionOverrides));
  }

  const getCellWithUpdatedContent = () => {
    const rowPropsOverrides = {
      columnOrder: singleCellColumnOrder,
      showComparison: true,
      columnsToCompare: [Columns.VERTICAL.name],
      lastRevisionToCompare: {
        [Columns.VERTICAL.name]: 'some text that was here before',
      },
    };
    const promotionOverrides = {
      [Columns.VERTICAL.name]: 'some text',
    };

    return getTable(getRow(demoPromotion, columnOrder, rowPropsOverrides, promotionOverrides));
  }

  const getCellWithRemovedContent = () => {
    const rowPropsOverrides = {
      columnOrder: singleCellColumnOrder,
      showComparison: true,
      columnsToCompare: [Columns.VERTICAL.name],
      lastRevisionToCompare: {
        [Columns.VERTICAL.name]: 'some text that was here before',
      },
    };
    const promotionOverrides = {
      [Columns.VERTICAL.name]: '',
    };

    return getTable(getRow(demoPromotion, columnOrder, rowPropsOverrides, promotionOverrides));
  }

  const getSection = ({ title, content, description }) => {
    return (
      <section className={styles.section}>
        <div className={styles.sectionHeader}>
          <Heading level={3}>{title}</Heading>
        </div>
        <div className={styles.sectionContainer}>
          <div className={styles.sectionDescription}>
            {description}
          </div>
          <div className={styles.sectionContent}>
            {content}
          </div>
        </div>
      </section>
    );
  };

  const getList = (items) => {
    const itemList = items.map(({ content, description }) => {
      return (
        <li
          key={description}
          className={styles.listItem}
        >
          <div className={styles.itemDescription}>{description}</div>
          <div className={styles.itemContent}>{content}</div>
        </li>
      );
    });

    return (
      <ul className={styles.list}>{itemList}</ul>
    );
  };

  const getNormalCellsSection = () => {
    const cellLegend = [
      {
        content: getInactiveCell(),
        description: ColorLegendLanguage.INACTIVE_CELL,
      },
      {
        content: getActiveCell(),
        description: ColorLegendLanguage.ACTIVE_CELL,
      },
      {
        content: getCellWithAnError(),
        description: ColorLegendLanguage.CELL_WITH_AN_ERROR,
      },
    ];

    return getSection({
      title: ColorLegendLanguage.CELLS,
      content: getList(cellLegend),
      description: ColorLegendLanguage.NORMAL_CELLS_DESCRIPTION,
    });
  };

  const getAuditCellsSection = () => {
    const cellLegend = [
      {
        content: getCellWithNewContent(),
        description: ColorLegendLanguage.NEW_CONTENT_ADDED_CELL,
      },
      {
        content: getCellWithUpdatedContent(),
        description: ColorLegendLanguage.CONTENT_UPDATED_CELL,
      },
      {
        content: getCellWithRemovedContent(),
        description: ColorLegendLanguage.CONTENT_REMOVED_CELL,
      },
    ];

    return getSection({
      title: ColorLegendLanguage.CELLS_AUDIT_MODE,
      content: getList(cellLegend),
      description: ColorLegendLanguage.AUDIT_CELLS_DESCRIPTION,
    });
  };

  const getRowsSection = () => {
    const cellLegend = [
      {
        content: getInactiveRow(),
        description: ColorLegendLanguage.INACTIVE_ROW,
      },
      {
        content: getActiveRow(),
        description: ColorLegendLanguage.ACTIVE_ROW,
      },
      {
        content: getPrintLockedRow(),
        description: ColorLegendLanguage.PRINT_LOCKED_ROW,
      },
      {
        content: getSoftDeletedRow(),
        description: ColorLegendLanguage.SOFT_DELETED_ROW,
      },
      {
        content: getEdlpRow(),
        description: ColorLegendLanguage.EDPL_ROW,
      },
      {
        content: getPrimeBenefitRow(),
        description: ColorLegendLanguage.PRIME_BENEFIT_ROW,
      },
      {
        content: getSelectedRow(),
        description: ColorLegendLanguage.SELECTED_ROW,
      },
      {
        content: getRowWithAnError(),
        description: ColorLegendLanguage.ROW_WITH_AN_ERROR,
      },
    ];

    return getSection({
      title: ColorLegendLanguage.ROWS,
      content: getList(cellLegend),
      description: ColorLegendLanguage.ROWS_DESCRIPTION,
    });
  };

  const getRelatedRows = () => {
    return getSection({
      title: ColorLegendLanguage.RELATED_ROWS,
      content: getTable(getHighlightedRelatedRows(demoPromotion, columnOrder), { showHeaders: true }),
      description: ColorLegendLanguage.RELATED_ROWS_DESCRIPTION,
    });
  };

  const getRelatedRowsWithEdlpSection = () => {
    return getSection({
      title: ColorLegendLanguage.RELATED_ROWS_WITH_EDLP,
      content: getTable(
        getHighlightedRelatedRows(demoPromotion, columnOrder, {}, { isEdlp: true }),
        { showHeaders: true }
      ),
      description: ColorLegendLanguage.RELATED_EDLP_ROWS_DESCRIPTION,
    });
  };

  const getRelatedRowsWithPrimeBenefitSection = () => {
    return getSection({
      title: ColorLegendLanguage.RELATED_ROWS_WITH_PRIME_BENEFIT,
      content: getTable(
        getHighlightedRelatedRows(demoPromotion, columnOrder, {}, { isPrimeBenefit: true }),
        { showHeaders: true }
      ),
      description: ColorLegendLanguage.RELATED_PRIME_BENEFITS_ROWS_DESCRIPTION,
    });
  };

  const getSplitView = (leftSideContent, rightSideContent) => {
    return (
      <div className={styles.splitViewSection}>
        <div className={styles.leftSideContent}>{leftSideContent}</div>
        <div className={styles.rightSideContent}>{rightSideContent}</div>
      </div>
    );
  };

  const getCellsSection = () => getSplitView(getNormalCellsSection(), getAuditCellsSection());

  const getContent = () => {
    const cellsSection = getCellsSection();
    const rowsSection = getRowsSection();
    const relatedRows = getRelatedRows();
    const relatedRowsWithEdlpSection = getRelatedRowsWithEdlpSection();
    const relatedRowsWithPrimeBenefitSection = getRelatedRowsWithPrimeBenefitSection();

    return (
      <div className={styles.colorLegendContainer}>
        {cellsSection}
        {rowsSection}
        {relatedRows}
        {relatedRowsWithEdlpSection}
        {relatedRowsWithPrimeBenefitSection}
      </div>
    );
  };
  
  const content = getContent();
  
  return (
    <Modal open={isColorLegendModalOpen} onClose={onClose}>
      <Heading level={4} className={styles.headingText}>
        {ColorLegendLanguage.MODAL_WINDOW_TITLE}
      </Heading>
      <div className={styles.contentContainer}>
        {content}
      </div>
    </Modal>
  );
};

ColorLegendModal.propTypes = {
  demoPromotion: PropTypes.object.isRequired,
  columnOrder: promotionColumnProps.isRequired,
  singleCellColumnOrder: promotionColumnProps.isRequired,
  isColorLegendModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ColorLegendModal;
