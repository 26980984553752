/* eslint-disable max-len */
import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Select, { SelectOption } from '@amzn/meridian/select';
import Button from '@amzn/meridian/button';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Link from '@amzn/meridian/link';
import Loader from '@amzn/meridian/loader';
import Row from '@amzn/meridian/row';
import styles from './PreviewInstructions.module.scss';
import { onSetPreviewDigitalCircularParamsIsLoading } from '../../actionCreators';


const PreviewInstructions = (props) => {
  const {
    cardParams,
    isLoading,
    storeRegions,
    onLoadDigitalCircularParams,
    onClearDigitalCircularParams,
    onCopyDigitalCircularCardParams,
    onCopyDigitalCircularCardId,
  } = props;
  const defaultRegion = 'LA1';
  const [open, setOpen] = useState(false);
  const [region, setRegion] = useState(defaultRegion);

  const onClickButton = () => {
    onLoadDigitalCircularParams(region);
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    onClearDigitalCircularParams();
  };

  const onRegionChanged = (newRegion) => {
    setRegion(newRegion);
    onLoadDigitalCircularParams(newRegion);
  };
  const createRegionSelector = () => {
    return (
      <Select
        value={region}
        onChange={onRegionChanged}
        width={300}
      >
        {storeRegions.options.map((storeRegion) => { return <SelectOption key={storeRegion} value={storeRegion} label={storeRegion} />; })}
      </Select>
    );
  };

  const getPreviewContent = () => {
    if (isLoading) {
      return <Loader />
    }
    return (
      <ol type="1">
        <li className={styles.instructionItem}> Select a Region to preview for
          {createRegionSelector()}
        </li>
        <li className={styles.instructionItem}>
          Open <Link href="https://pre-prod.amazon.com/cards/testpage/render-form" target="_blank" rel="noopener noreferrer">Preview render page</Link>. <b>Make sure you are VPN'ed in</b>, otherwise the page will not work.
        </li>
        <li className={styles.instructionItem}>
          Click on <Button size='small' type='primary' onClick={onCopyDigitalCircularCardId}>Copy id</Button> <pre className={styles.code}>f3-digital-circular-card</pre> and paste into <code className={styles.code}>Card id</code> section
        </li>
        <li className={styles.instructionItem}>
          Click on <Button size='small' type='primary' onClick={onCopyDigitalCircularCardParams}>Copy params</Button> <pre className={classnames(styles.code, styles.codeBlock)}>{cardParams}</pre>
          and paste into <code className={styles.code}>Card params</code> section
        </li>
        <li className={styles.instructionItem}>
          Press the Submit button at the bottom of the Preview render page
        </li>
      </ol>
    )
  };

  const previewContent = getPreviewContent()

  return (
    <>
      <Button size="small" type="primary" onClick={onClickButton}>Preview</Button>
      <Modal
        title="Preview Instruction"
        open={open}
        onClose={onClose}
        scrollContainer="viewport"
        closeLabel="Close"
        aria-describedby="modal-description"
      >
        {previewContent}
        <ModalFooter>
          <Row alignmentHorizontal="end" widths="fit">
            <Button type="primary" size="small" onClick={onClose}>
              Close
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  );
};

PreviewInstructions.propTypes = {
  digitalCircularParams: PropTypes.string,
  isLoading: PropTypes.bool,
  onLoadDigitalCircularParams: PropTypes.func,
  onClearDigitalCircularParams: PropTypes.func,
};

PreviewInstructions.defaultProps = {
  digitalCircularParams: '',
  isLoading: true,
  onLoadDigitalCircularParams: _.noop,
  onClearDigitalCircularParams: _.noop,
};

export default PreviewInstructions;
