import moment from 'moment';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import Main from './Main';
import { Columns, Form } from '../../../../constants';
import { onAsinValidatorSendAsins, onAsinValidatorSetOpen } from '../../../../actionCreators';
import extractUniqueAsins from '../../../../helpers/extractUniqueAsins';

const selector = formValueSelector(Form.PROMO);

const getUniqueAsins = (state) => {
  const selectedAsins = selector(state, Columns.ASINS.name);
  if (!selectedAsins) {
    return [];
  }

  return extractUniqueAsins(selectedAsins);
};

const mapStateToProps = (state) => {
  const {
    AsinValidator: {
      isLoading: asinValidatorIsLoading,
    },
    Promo: {
      dates,
    },
  } = state;

  const [startDate] = dates;
  return {
    startDate: moment(startDate).format('YYYY-MM-DD'),
    uniqueAsins: getUniqueAsins(state),
    auditedStoreRegions: selector(state, Columns.AUDITED_STORE_REGIONS.name),
    asinValidatorIsLoading,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onAsinImageSelect({ url }) {
      dispatch(change(Form.PROMO, Columns.ASIN_IMAGE.name, url, true));
    },
    onAsinAvailabilityRequested(asins, startDate) {
      dispatch(onAsinValidatorSetOpen(true));
      dispatch(onAsinValidatorSendAsins(asins, startDate));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
