import React from 'react';
import { Columns } from '../constants';

const withStoreRegionCustomization = (Component) => (props) => {
  return (
    <Component
      {...props}
      label={Columns.REGIONS.display}
      showSelectAll
      autoOpen
    />
  );
};

export default withStoreRegionCustomization;
